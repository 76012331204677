import { Button } from "@jobber/components/Button"
import { Checkbox } from "@jobber/components/Checkbox"
import { Content } from "@jobber/components/Content"
import { Disclosure } from "@jobber/components/Disclosure"
import { InputNumber } from "@jobber/components/InputNumber"
import { InputText } from "@jobber/components/InputText"
import { Modal } from "@jobber/components/Modal"
import { Spinner } from "@jobber/components/Spinner"
import { Text } from "@jobber/components/Text"
import { showToast } from "@jobber/components/Toast"
import { useVirtualizer } from "@tanstack/react-virtual"
import { endOfDay, formatISO, startOfDay } from 'date-fns'
import React, { useContext, useEffect, useRef, useState } from "react"
import ProgressBar from 'react-bootstrap/ProgressBar'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from "react-redux"
import { io } from "socket.io-client"
import { postToServer } from "../../components/Common/requests"
import { SocketContext } from "../../SocketProvider"
import { setLoginUser } from "../../store/userSlice"
import useGetFromServer from "../../utils/useGetFromServer"
import ResetSidebar from "../../components/Common/ResetSidebar"
import { Tabs, Tab } from "@jobber/components/Tabs"

export default function DraftQuotePage() {
  async function syncDraft() {
    if (!minQuoteNumber || !maxQuoteNumber || minQuoteNumber > maxQuoteNumber) {
      showToast({
        message: 'Please provide valid quote number',
        variation: 'error'
      });
      return;
    }

    if (!date[0].startDate || !date[0].endDate) {
      showToast({
        message: 'Please provide start and end date',
        variation: 'error'
      });
      return;
    }

    const adjustedMinDateUTC = startOfDay(date[0].startDate);
    const adjustedMaxDateUTC = endOfDay(date[0].endDate);

    const minDate = formatISO(adjustedMinDateUTC);
    const maxDate = formatISO(adjustedMaxDateUTC);

    setModalOpen(false)
    setProgressLoading(true);
    await postToServer('draft-quotes', {
      minQuoteNumber,
      maxQuoteNumber,
      minDate,
      maxDate
    })

    setMinQuoteNumber()
    setMaxQuoteNumber()
    setDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ])
  }

  const dispatch = useDispatch();
  const [progressValue, setProgressValue] = useState(1);
  const [progressLoading, setProgressLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const { socket, socketHandler } = useContext(SocketContext)
  const userStore = useSelector((store) => store.user);
  const user = userStore?.user?.data;
  const [modalOpen, setModalOpen] = useState(false)
  const [minQuoteNumber, setMinQuoteNumber] = useState()
  const [maxQuoteNumber, setMaxQuoteNumber] = useState()
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [refetch, setRefetch] = useState(false)
  const [groupedData, setGroupedData] = useState()
  const [fixedGroupedData, setFixedGroupedData] = useState()

  useEffect(() => {
    if (!socket) {
      const newSocket = io(process.env.REACT_APP_API_URL);
      socketHandler(newSocket);
    }

    if (socket) {
      socket.on(`FixQuoteProcessStart_${user?._id}`, (msg) => {
        setProgressLoading(true);
        console.log(typeof msg.progress)
        setProgressValue(msg.progress);
        setCurrentStatus(msg.message);
        showToast({
          message: msg.message,
          variation: 'info'
        });
        dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: msg.progress } }));
      });

      socket.on(`FixQuoteProcess_${user?._id}`, (msg) => {
        setProgressLoading(true);
        setProgressValue(msg.progress);
        setCurrentStatus(msg.message);
        dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: true, progress: msg.progress } }));
      });

      socket.on(`FixQuoteProcessComplete_${user?._id}`, (msg) => {
        setProgressLoading(false);
        setProgressValue(msg.progress);
        setCurrentStatus(msg.message);
        showToast({
          message: msg.message,
          variation: 'info'
        });
        dispatch(setLoginUser({ ...userStore?.user, data: { ...userStore?.user?.data, isQuoteSyncing: false, progress: msg.progress } }));
        setRefetch(!refetch)
      });
    }
  }, [socket])

  const { data, loading } = useGetFromServer(`draft-quotes?isFixed=${false}`, [refetch])
  const { data: fixedData, loading: fixedLoading } = useGetFromServer(`draft-quotes?isFixed=${true}`, [refetch])

  function groupObjectsByLineItems(objects) {
    // Helper function to create a unique key for the lineItems array
    function createKey(lineItems) {
      return JSON.stringify(
        lineItems.map(item => ({
          name: item.name,
          description: item.description,
        }))
      );
    }

    // Map to hold groups
    const groupedObjects = new Map();

    objects.forEach(obj => {
      const key = createKey(obj.lineItems);

      // If the key doesn't exist, initialize it with an empty array
      if (!groupedObjects.has(key)) {
        groupedObjects.set(key, {
          group: [],
          lineItems: obj.lineItems.map(item => ({
            id: item.id,
            name: item.name,
            description: item.description,
            editDescription: ''
          }))
        });
      }

      // Add the current object to the group
      groupedObjects.get(key).group.push(obj);
    });

    // Extract the groups and their unique lineItems
    const result = Array.from(groupedObjects.values()).map(groupData => ({
      objects: groupData.group,
      uniqueLineItems: groupData.lineItems
    }));

    return result;
  }

  useEffect(() => {
    setGroupedData(groupObjectsByLineItems(data))
    setFixedGroupedData(groupObjectsByLineItems(fixedData))
  }, [data, fixedData])

  if (progressLoading) {
    return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        <p className="text-center">{currentStatus}</p>
        <ProgressBar animated variant="success" label={`${progressValue?.toFixed(2)}%`} now={progressValue} />
        <p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} className="mt-1">
          Do not perform any operation, while we are syncing
        </p>
      </div>
    </div>
  }

  function NumberInputValidation() {
    if (minQuoteNumber && maxQuoteNumber && minQuoteNumber > maxQuoteNumber) {
      return "Minimum Quote Number should be less than Maximum Quote Number"
    }
  }

  function disclosureTitle(group, index) {
    return `Group ${index + 1} #(${group.objects[0].quoteNumber} - ${group.objects[group.objects.length - 1].quoteNumber}) (${group.objects.length} items)`
  }

  return (
    <>
      <ResetSidebar />
      <div>
        <div className='d-flex align-items-center gap-2'>
          <h1>Draft Quote Page</h1>
          <Button onClick={() => setModalOpen(true)} label='Sync Draft Quotes' />
          <Modal
            title={"Set filter"}
            open={modalOpen}
            onRequestClose={() => {
              setModalOpen(false)
              setMinQuoteNumber()
              setMaxQuoteNumber()
              setDate([
                {
                  startDate: new Date(),
                  endDate: new Date(),
                  key: 'selection'
                }
              ])
            }}
            primaryAction={{ label: 'Sync', onClick: syncDraft }}
          >
            <Content>
              <InputNumber placeholder='Minimum Quote Number to Filter' onChange={setMinQuoteNumber} value={minQuoteNumber} validations={{
                required: 'Minimum Number is required',
                validate: NumberInputValidation
              }} />
              <InputNumber placeholder='Maximum Quote Number to Filter' onChange={setMaxQuoteNumber} value={maxQuoteNumber} validations={{
                required: 'Maximum Number is required',
                validate: NumberInputValidation
              }} />
              <DateRangePicker
                rangeColors={['#7DB00E']}
                onChange={(item) => setDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={date}
              />
            </Content>
          </Modal>
        </div>
        <Tabs defaultActiveTab={0}>
          <Tab label='Draft Quotes'>
            {loading ?
              <div aria-hidden="true" className='placeholder-glow d-flex flex-column gap-2'>
                <span style={{ height: '50px' }} class="rounded placeholder w-100"></span>
                <span style={{ height: '25px' }} class="rounded placeholder w-50"></span>
              </div> : <>
                {!loading && groupedData.map((group, index) => (
                  <Disclosure key={disclosureTitle(group, index)} title={disclosureTitle(group, index)}>
                    <VirtualizedContent quotes={group.objects} uniqueLineItems={group.uniqueLineItems} />
                  </Disclosure>
                ))}
              </>}
          </Tab>
          <Tab label='Fixed Draft Quotes'>
            {fixedLoading ?
              <div aria-hidden="true" className='placeholder-glow d-flex flex-column gap-2'>
                <span style={{ height: '50px' }} class="rounded placeholder w-100"></span>
                <span style={{ height: '25px' }} class="rounded placeholder w-50"></span>
              </div> : <>
                {fixedGroupedData.map((group, index) => (
                  <Disclosure key={disclosureTitle(group, index)} title={disclosureTitle(group, index)}>
                    <VirtualizedContent quotes={group.objects} uniqueLineItems={group.uniqueLineItems} isFixedPage />
                  </Disclosure>
                ))}
              </>}
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

function VirtualizedContent({ quotes, uniqueLineItems, isFixedPage = false }) {
  const parentRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [lineItems, setLineItems] = useState([])
  const [selected, setSelected] = useState([])
  const [selectedLineItems, setSelectedLineItems] = useState([])

  async function primaryAction() {
    setEditModalOpen(false)
    await postToServer('draft-quotes-fix', { quoteIds: selected, selectedLineItems })
    setSelectedLineItems([])
  }

  function closeEditModal() {
    setSelectedLineItems([])
    setEditModalOpen(false)
  }

  function onChangeHandler(val, virtualRow) {
    if (val) {
      setSelected([...selected, quotes[virtualRow.index]._id])
    } else {
      setSelected(selected.filter(quote => quote !== quotes[virtualRow.index]._id))
    }
  }

  function modalHandler(items) {
    setModalOpen(true)
    setLineItems(items)
  }

  const rowVirtualizer = useVirtualizer({
    count: quotes.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 45, // Adjust according to the estimated height of each quote item
  });

  return (
    <div style={{ flex: '1' }}>
      <Modal
        title={"Line Items"}
        open={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        <Content>
          <div className="d-flex flex-column gap-2">
            {lineItems.map((item, index) => (
              <div key={index} className='d-flex flex-column gap-1 p-2 border rounded-2'>
                <p className='m-0'><b>{item.name}</b></p>
                <p style={{ whiteSpace: 'pre-line' }} className='m-0'>{item.description}</p>
              </div>
            ))}
          </div>
        </Content>
      </Modal>
      <Modal
        title={"Bulk Edit LineItems"}
        open={editModalOpen}
        onRequestClose={closeEditModal}
        primaryAction={{ label: "Submit", onClick: primaryAction }}
      >
        <Content>
          <Text>Select the line item to edit</Text>
          {uniqueLineItems.map((item, index) => (
            <div key={index} className='d-flex gap-2 align-items-center'>
              <Checkbox
                name={item.name + item.description}
                onChange={
                  (val) => {
                    if (val) {
                      setSelectedLineItems([...selectedLineItems, { ...item }])
                    } else {
                      setSelectedLineItems(selectedLineItems.filter(selectedItem => selectedItem.id !== item.id));
                    }
                  }
                } checked={selectedLineItems.some(selectedItem => selectedItem.id === item.id)} />
              <div className="d-flex flex-column gap-1 p-2 border rounded-2">
                <Text><b>{item.name}</b></Text>
                {selectedLineItems.some(selectedItem => selectedItem.id === item.id) && (
                  <>
                    <div style={{ whiteSpace: 'pre-line' }}>
                      <Text>{item.description}</Text>
                    </div>
                    <EditDocument
                      id={
                        selectedLineItems.find(selectedItem => selectedItem.id === item.id).id
                      }
                      setSelectedItems={setSelectedLineItems}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
        </Content>
      </Modal>
      <Content>
        {!isFixedPage && <Button label='Edit' onClick={() => setEditModalOpen(true)} disabled={!selected.length} />}
        <div ref={parentRef} style={{ maxHeight: '400px', overflowY: 'auto', position: 'relative' }}>
          <div style={{
            display: 'grid',
            gridTemplateColumns: `${!isFixedPage ? '.2fr' : ''} 1fr 1fr 1fr 1fr`,
            gap: '0.4rem',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1,
            padding: '0.2rem 0',
          }}>
            {!isFixedPage && <p className='m-0'><b>{selected.length}</b></p>}
            <p className='m-0'><b>Title</b></p>
            <p className='m-0'><b>Client Name</b></p>
            <p className='m-0'><b>Quote Number</b></p>
            <p className='m-0'><b>Line Items</b></p>
          </div>
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: 'relative'
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow, index) => (
              <div
                key={quotes[virtualRow.index].quoteNumber + index}
                ref={virtualRow.measureElement}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualRow.start}px)`,
                  display: 'grid',
                  gridTemplateColumns: `${!isFixedPage ? '.2fr' : ''} 1fr 1fr 1fr 1fr`,
                  gap: '0.4rem',
                }}
              >
                {!isFixedPage && <Checkbox onChange={(val) => onChangeHandler(val, virtualRow)} checked={selected.includes(quotes[virtualRow.index]._id)} />}
                <Text>
                  {quotes[virtualRow.index].title}
                </Text>
                <Text>
                  {quotes[virtualRow.index].clientName}
                </Text>
                <Text>
                  {quotes[virtualRow.index].quoteNumber}
                </Text>
                <div className="d-flex align-items-center">
                  <span>
                    <Button label='Check' onClick={() => modalHandler(quotes[virtualRow.index].lineItems)} fullWidth={false} />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Content>
    </div>
  );
}

function EditDocument({ id, setSelectedItems }) {
  const [value, setValue] = useState('')

  return (
    <InputText
      multiline
      value={value}
      onChange={setValue}
      onBlur={() =>
        setSelectedItems((prev) =>
          prev.map((item) => {
            if (item.id === id) {
              return { ...item, editDescription: value }
            }
            return item
          })
        )
      }
    />
  )
}

import { Combobox } from '@jobber/components/Combobox';
import React, { useRef } from 'react';
import useGetFromServer from '../../../../utils/useGetFromServer';

export default function Titles({ getTitles, initialValue }) {
  const { data } = useGetFromServer(`quoteTitles`)
  const selected = useRef(initialValue ? initialValue.map((title, index) => ({ id: index, label: title })).filter(item => !!item.label) : [])

  return (
    <Combobox
      multiSelect
      label='Select Titles'
      onSelect={(selection) => {
        selected.current = selection;
        getTitles(selection.map((title) => title));
      }}
      selected={selected.current}
    >
      {data.map((title, i) => <Combobox.Option key={`${i}-${title}`} id={i} label={title} />)}
    </Combobox>
  );
}

import React from 'react'
import Filters from '../Filters/Filters'
import InstructionToolTip from '../PageInstruction/instruction'
import Titles from '../Titles/Titles'
import YearPicker from '../YearPicker/YearPicker'

export default function Header({ getYear, getTags, getTitles }) {
  const savedData = JSON.parse(localStorage.getItem('quotesPreparationData')) ?? {}

  return (
    <div className='d-flex gap-3'>
      <YearPicker getYear={getYear} initialValue={savedData.selectedYear} />
      <Filters getTags={getTags} initialValue={savedData.tags} />
      <Titles getTitles={getTitles} initialValue={savedData.titles} />
      <InstructionToolTip />
    </div>
  )
}

import React, { useState } from 'react';
import { LuContact2 } from 'react-icons/lu';
import logo from '../../assets/logo.svg';
import { Divider } from '@jobber/components/Divider';
import { Link, useLocation } from 'react-router-dom';
import { MdRequestQuote, MdSpaceDashboard, MdSpeakerNotes } from 'react-icons/md';
import { IoSettingsSharp } from 'react-icons/io5';
import { BsSuitcaseLgFill } from 'react-icons/bs';
import { FaCalendarDays } from 'react-icons/fa6';
import { MdPriceChange } from "react-icons/md";
import { FaRecycle } from 'react-icons/fa';

const menuItems = [
	{
		icon: <MdSpaceDashboard className="fw-bold fs-5" />,
		to: '/dashboard',
		label: 'Dashboard'
	},
	{
		icon: <LuContact2 className="fw-bold fs-5" />,
		to: '/clients',
		label: 'Clients'
	},
	{
		icon: <MdRequestQuote className="fw-bold fs-5" />,
		to: '/advance-quotes',
		label: 'Advance Quotes'
	},
	{
		icon: <MdRequestQuote className="fw-bold fs-5" />,
		to: '/quotes-prep',
		label: 'Quotes Preparation'
	},
	{
		icon: <MdRequestQuote className="fw-bold fs-5" />,
		to: '/draft-quotes',
		label: 'Quotes Fix'
	},
	{
		icon: <FaCalendarDays className="fw-bold fs-5" />,
		to: '/advance-visits',
		label: 'Advance Visits'
	},
	{
		icon: <BsSuitcaseLgFill className="fw-bold fs-5" />,
		to: '/job-automation',
		label: 'Job Automation'
	},
	{
		icon: <MdPriceChange className="fw-bold fs-5" />,
		to: '/approved-quotes',
		label: 'Approved Quotes'
	},
	// {
	// 	icon: <FaRecycle className="fw-bold fs-5" />,
	// 	to: "/recycle-quotes",
	// 	label: "Recycle Quotes",
	// },
	{
		icon: <MdSpeakerNotes className="fw-bold fs-5" />,
		to: '/advance-notes',
		label: 'Advance Notes'
	},
	// {
	// 	icon: <BsSuitcaseLgFill className="fw-bold fs-5" />,
	// 	to: "/jobs",
	// 	label: "Closed Jobs",
	// },
	{
		icon: <IoSettingsSharp className="fw-bold fs-5" />,
		to: '/settings',
		label: 'Settings'
	}
];

function Sidebar() {
	const location = useLocation();
	return (
		<div className="sidebar">
			<div className="l-navbar show sidebarmenu" id="nav-bar">
				<nav className="nav">
					<div className="fw-bold">
						<a href="#" className="nav_logo">
							<i className="bx bx-layer nav_logo-icon"></i>{' '}
							<span className="nav_logo-name">
								<img src={logo} alt="" />
							</span>
						</a>
						<div className="nav_list">
							{menuItems &&
								menuItems?.map((item, index) => {
									return location.pathname == item.to ? (
										<span className={`pointer nav_link disabled ${location.pathname == item.to ? 'active' : ''}`}>
											{item.icon}
											<span className="nav_name">{item.label}</span>
										</span>
									) : (
										<Link to={item.to} className={`nav_link ${location.pathname == item.to ? 'active' : ''}`}>
											{item.icon}
											<span className="nav_name">{item.label}</span>
										</Link>
									);
								})}
						</div>
					</div>
				</nav>
			</div>
		</div>
	);
}

export default Sidebar;

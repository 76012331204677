import { Button } from '@jobber/components/Button';
import { Card } from '@jobber/components/Card';
import { Content } from '@jobber/components/Content';
import { InputText } from '@jobber/components/InputText';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Combobox } from '@jobber/components/Combobox';
import { Chip } from '@jobber/components/Chip';
import { Icon } from '@jobber/components/Icon';
import BulkTagSVG from '../../Index/BulkTagMangement/BulkTagSVG';
import { IoClose } from 'react-icons/io5';
import { Spinner } from '@jobber/components/Spinner';
import { setRecycleTabsR, setTabs } from '../../../store/AdvanceQuotes/TabsSlice';
import { deleteFromServer, getFromServer, postToServer, putToServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import LockerModal from '../../../components/Common/LockerModal';
import { useLocation } from 'react-router';
import { Checkbox } from '@jobber/components/Checkbox';
import jobberLogo from '../../../assets/logoBlack.svg';
import { SiQuickbooks } from 'react-icons/si';
import DateRange from '../Table/_components/DateRange';
import { endOfYear, startOfYear } from "date-fns";


function Page({ setShowPage, item, setItem, tabChangeHandler, setTab, setVisibleColumns }) {
	const dispatch = useDispatch();
	const location = useLocation();
	const [isAdvanceQuotes, setIsAdvanceQuotes] = useState(!location?.pathname.includes('recycle'));
	useEffect(() => {
		setIsAdvanceQuotes(!location?.pathname.includes('recycle'));
	}, [location?.pathname]);
	const user = useSelector((s) => s.user);
	const tabs = useSelector((store) => store.tabs);
	const [pageName, setPageName] = useState(item ? item.pageName : '');
	const [ignoreTax, setIgnoreTax] = useState(item ? item.ignoreTaxAmount || false : false);
	const [totalCost, setTotalCost] = useState(item ? item?.columnPreferences?.totalCost || false : false);
	const [amountPaid, setAmountPaid] = useState(item ? item?.columnPreferences?.amountPaid || false : false);
	const [amountOwed, setAmountOwed] = useState(item ? item?.columnPreferences?.amountOwed || false : false);
	const [QBtotalCost, setQBtotalCost] = useState(item ? item?.columnPreferences?.QBtotalCost || false : false);
	const [QBamountPaid, setQBamountPaid] = useState(item ? item?.columnPreferences?.QBamountPaid || false : false);
	const [QBamountOwed, setQBamountOwed] = useState(item ? item?.columnPreferences?.QBamountOwed || false : false);
	const [totalAmountOwed, setTotalAmountOwed] = useState(item ? item?.columnPreferences?.totalAmountOwed || false : false);
	const [description, setDescription] = useState(item ? item.pageDescription : '');
	const [includeSelected, setIncludeSelected] = useState(item ? item.tags.include.map((t) => ({ id: t, label: t })) : []);
	const [excludeSelected, setExcludeSelected] = useState(item ? item.tags.exclude.map((t) => ({ id: t, label: t })) : []);
	useEffect(() => {
		setPageName(item ? item.pageName : '');
		setDescription(item ? item.pageDescription || '' : '');
		setIgnoreTax(item ? item.ignoreTaxAmount || false : false);
		setTotalCost(item ? item?.columnPreferences?.totalCost || false : false);
		setAmountPaid(item ? item?.columnPreferences?.amountPaid || false : false);
		setAmountOwed(item ? item?.columnPreferences?.amountOwed || false : false);
		setQBtotalCost(item ? item?.columnPreferences?.QBtotalCost || false : false);
		setQBamountPaid(item ? item?.columnPreferences?.QBamountPaid || false : false);
		setQBamountOwed(item ? item?.columnPreferences?.QBamountOwed || false : false);
		setTotalAmountOwed(item ? item?.columnPreferences?.totalAmountOwed || false : false);
		setIncludeSelected(item ? item.tags.include.map((t) => ({ id: t, label: t })) : []);
		setExcludeSelected(item ? item.tags.exclude.map((t) => ({ id: t, label: t })) : []);
	}, [item]);

	const tagSlice = useSelector((store) => store.tag);

	const includeTagRemoveHandler = (index) => {
		setIncludeSelected(includeSelected.filter((value, i) => index !== i));
	};
	const excludeTagRemoveHandler = (index) => {
		setExcludeSelected(excludeSelected.filter((value, i) => index !== i));
	};

	const [loading, setLoading] = useState(false);
	const handleAddPage = async () => {
		const include = [];
		const exclude = [];
		includeSelected?.forEach((t) => {
			include.push(t.label);
		});
		excludeSelected?.forEach((t) => {
			exclude.push(t.label);
		});
		const body = {
			pageName,
			ignoreTaxAmount: ignoreTax,
			pageDescription: description,
			startRangeOfYear,
			endRangeOfYear,
			yearCreated: Number(yearCreated.format('YYYY')),
			columnPreferences: {
				totalCost,
				amountPaid,
				amountOwed,
				QBtotalCost,
				QBamountPaid,
				QBamountOwed,
				totalAmountOwed
			},
			tags: { include, exclude }
		};
		setLoading(true);
		const result = item ? await putToServer(`page/${item._id}`, body) : await postToServer('page', body);
		if (result.status) {
			setVisibleColumns((prev) => ({
				...prev,
				totalCost: result?.data?.columnPreferences?.totalCost,
				amountPaid: result?.data?.columnPreferences?.amountPaid,
				amountOwed: result?.data?.columnPreferences?.amountOwed,
				QBtotalCost: result?.data?.columnPreferences?.QBtotalCost,
				QBamountPaid: result?.data?.columnPreferences?.QBamountPaid,
				QBamountOwed: result?.data?.columnPreferences?.QBamountOwed,
				totalAmountOwed: result?.data?.columnPreferences?.totalAmountOwed
			}));
			setShowPage(false);
			setItem(null);
			if (item) {
				const newData = [...tabs.tabs];
				const index = newData.findIndex((t) => t._id == item._id);
				if (index > -1) {
					newData[index] = result.data;
				}
				dispatch(setTabs(newData));
				item && tabChangeHandler(item._id);
			} else {
				const newTabs = [...tabs.tabs];
				const secondLastIndex = newTabs.length - 1; // Since we're adding before the last element
				newTabs.splice(secondLastIndex, 0, result.data);
				dispatch(setTabs(newTabs));
			}
			showToast({
				message: `Page ${item ? 'updated' : 'created'} successfully`
			});
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setLoading(false);
	};

	const [restoreLoading, setRestoreLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [lockModal, setLockModal] = useState(false);
	const handleDeletePage = async () => {
		setDeleteLoading(true);
		setLockModal(false);
		// const result = isAdvanceQuotes ? await putToServer(`archieve-page/${item._id}`) : await deleteFromServer(`Archieve-Page/${item._id}`);
		const result = await putToServer(`archieve-page/${item._id}`);
		if (result.status) {
			setShowPage(false);
			setItem(null);
			const newData = tabs.tabs?.filter((e) => e._id !== item._id);
			dispatch(setTabs(newData));
			setTab(tabs.tabs?.[0]?._id);
			tabChangeHandler(tabs.tabs?.[0]?._id);
			// if (!isAdvanceQuotes) {
			//   const result2 = await getFromServer(`archieve-pages`);
			//   if (result2.status) {
			//     dispatch(setRecycleTabsR(result2.data));
			//   }
			// }
			showToast({
				message: `Page Deleted successfully`
			});
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setDeleteLoading(false);
	};
	const handleRestorePage = async () => {
		setRestoreLoading(true);
		const result = await putToServer(`unarchieve-page/${item._id}`);
		if (result.status) {
			setShowPage(false);
			setItem(null);
			const newData = tabs.recycleTabs?.filter((e) => e._id !== item._id);
			dispatch(setRecycleTabsR(newData));
			setTab(tabs.tabs?.[0]?._id);
			tabChangeHandler(tabs.tabs?.[0]?._id);
			showToast({
				message: `Page Restored successfully`
			});
			const result2 = await getFromServer(`pages`);
			if (result2.status) {
				dispatch(setTabs(result2.data));
			}
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setDeleteLoading(false);
	};

	const [startRangeOfYear, setStartRangeOfYear] = useState(item?.startRangeOfYear ?? startOfYear(new Date()));
	const [endRangeOfYear, setEndRangeOfYear] = useState(item?.endRangeOfYear ?? endOfYear(new Date()));
	const [yearCreated, setYearCreated] = useState(item?.yearCreated ?? new Date().getFullYear());

	return (
		<div className="mb-4">
			<LockerModal open={lockModal} onConfirm={handleDeletePage} onCancel={() => setLockModal(false)} />
			<Card
				header={{
					title: item ? 'Edit Page' : `Add Page`,
					action: <Button label="X" onClick={() => setShowPage(false)} />
				}}
			>
				<Content>
					{isAdvanceQuotes && (
						<>
							<Row>
								<Col lg={6}>
									<InputText
										placeholder="Page Name"
										name="pageName"
										value={pageName}
										onChange={setPageName}
										validations={{
											required: {
												value: true,
												message: 'Tell us your page name'
											}
										}}
									/>
								</Col>
								<Col lg={6}>
									<InputText placeholder="Description" name="description" value={description} onChange={setDescription} />
								</Col>
							</Row>
							<Row className="my-4">
								<Col lg={4} className='py-3'>
									<Checkbox label={'Ignore Tax Amount?'} checked={ignoreTax} onChange={setIgnoreTax} />
									<DateRange startDate={startRangeOfYear} setStartDate={setStartRangeOfYear} endDate={endRangeOfYear} setEndDate={setEndRangeOfYear} forYear={yearCreated} setForYear={setYearCreated} />
								</Col>
								<Col>
									<div className='border mb-2 position-relative py-3 px-4 rounded gap-2' style={{
										display: 'grid',
										gridTemplateColumns: '50px repeat(3, 1fr)',
										alignItems: 'center'
									}}>
										<img style={{ position: 'absolute', left: -15, top: '50%', translate: '0 -50%' }} src={jobberLogo} alt="" />
										<p className='m-0 fw-bold'>Show:</p>
										<Checkbox label={'Total Amount'}
											checked={totalCost}
											onChange={setTotalCost}
										/>
										<Checkbox label={'Amount Paid'}
											checked={amountPaid}
											onChange={setAmountPaid}
										/>
										<Checkbox label={'Amount Owed'}
											checked={amountOwed}
											onChange={setAmountOwed}
										/>
									</div>
									<div className='border mb-2 position-relative py-3 px-4 rounded gap-2' style={{
										display: 'grid',
										gridTemplateColumns: '50px repeat(3, 1fr)',
										alignItems: 'center'
									}}>
										<SiQuickbooks className='fs-3' style={{ position: 'absolute', left: -15, top: '50%', translate: '0 -50%' }} />
										<p className='m-0 fw-bold'>Show:</p>
										<Checkbox label={'Total Amount'}
											checked={QBtotalCost}
											onChange={setQBtotalCost}
										/>
										<Checkbox label={'Amount Paid'}
											checked={QBamountPaid}
											onChange={setQBamountPaid}
										/>
										<Checkbox label={'Amount Owed'}
											checked={QBamountOwed}
											onChange={setQBamountOwed}
										/>
									</div>
									<div className='d-flex align-items-center border py-3 px-4 rounded gap-2'>
										<Checkbox label={'Total Amount Owed'} checked={totalAmountOwed} onChange={setTotalAmountOwed} />
										<span>
											(<img src={jobberLogo} height='20' alt="" /> Total Amount - <SiQuickbooks className='fs-5 ms-2' /> Total Paid)
										</span>
									</div>
								</Col>
							</Row>
							<Row>
								<div className="d-flex gap-3 align-items-center">
									<Combobox
										multiSelect
										onSelect={(selection) => {
											setIncludeSelected(selection);
										}}
										selected={includeSelected}
									>
										<Combobox.Activator>
											<Chip heading={'Include Tags'} variation={'subtle'}>
												<Chip.Suffix>
													<Icon name={'add'} size={'large'} />
												</Chip.Suffix>
											</Chip>
										</Combobox.Activator>
										{tagSlice?.allTags ? tagSlice.allTags.map((t) => <Combobox.Option id={t.label} label={t.label} />) : null}
										{/* <Combobox.Action
											visible={({ searchValue }) => Boolean(searchValue)}
											label={({ searchValue }) => `Add ${searchValue} as tag`}
											onClick={(_, { searchValue }) => {
												alert(`Added ${searchValue} as tag`);
											}}
										/> */}
									</Combobox>
									<div className={`${includeSelected?.length == 1 ? '' : 'row'} mt-3 mb-2`}>
										{includeSelected &&
											includeSelected?.map((t, index) => {
												return (
													<div className="col-auto d-flex mb-2" style={{ marginRight: '-25px', position: 'relative' }} key={t._id}>
														<BulkTagSVG text={t.label} id={t} count={1} />
														<div
															style={{ position: 'absolute' }}
															className="bg-light h-100 border rounded"
															onClick={() => includeTagRemoveHandler(index)}
														>
															<IoClose className="fs-6 pointer" />
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</Row>
							<Row>
								<div className="d-flex gap-3 align-items-center">
									<Combobox
										multiSelect
										onSelect={(selection) => {
											console.log(selection);
											setExcludeSelected(selection);
										}}
										selected={excludeSelected}
									>
										<Combobox.Activator>
											<Chip heading={'Exclude Tags'} variation={'subtle'}>
												<Chip.Suffix>
													<Icon name={'add'} size={'large'} />
												</Chip.Suffix>
											</Chip>
										</Combobox.Activator>
										{tagSlice?.allTags ? tagSlice.allTags.map((t) => <Combobox.Option id={t._id} label={t.label} />) : null}
									</Combobox>
									<div className={`${excludeSelected?.length == 1 ? '' : 'row'} mt-3 mb-2`}>
										{excludeSelected &&
											excludeSelected?.map((t, index) => {
												return (
													<div className="col-auto d-flex mb-2" style={{ marginRight: '-25px', position: 'relative' }} key={t._id}>
														<BulkTagSVG text={t.label} id={t} count={1} />
														<div
															style={{ position: 'absolute' }}
															className="bg-light h-100 border rounded"
															onClick={() => excludeTagRemoveHandler(index)}
														>
															<IoClose className="fs-6 pointer" />
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</Row>
						</>
					)}
					<div className="text-center">
						{deleteLoading ? (
							<Spinner size="base" inline />
						) : (
							item && (
								<Button
									variation="destructive"
									label={isAdvanceQuotes ? 'Delete' : 'Permanently Delete'}
									onClick={user?.user?.data?.isPasswordCreated ? () => setLockModal(true) : handleDeletePage}
								// onClick={handleDeletePage}
								/>
							)
						)}
						<span className="ms-3"></span>
						{loading ? (
							<Spinner size="base" inline />
						) : (
							isAdvanceQuotes && <Button label={item ? 'Update Page' : 'Add Page'} onClick={handleAddPage} />
						)}
						{restoreLoading ? (
							<Spinner size="base" inline />
						) : (
							!isAdvanceQuotes && <Button label={'Restore Page'} onClick={handleRestorePage} />
						)}
					</div>
				</Content>
			</Card>
		</div>
	);
}

export default Page;

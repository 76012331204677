import { InputDate } from "@jobber/components/InputDate";
import moment from "moment";
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';

function DateRange({ startDate, setStartDate, endDate, setEndDate, forYear, setForYear }) {
  const startOfCurrentYear = moment().add(1, 'year').startOf('year');
  function valid(current) {
    return current.isBefore(startOfCurrentYear);
  };

  function setYear(value) {
    if (!moment.isMoment(value)) {
      console.error("Invalid date format. Expected a moment object.");
      return;
    }

    const year = value.year(); // Extract year
    const momentYear = moment(`${year}`, 'YYYY'); // Create moment object
    setForYear(momentYear);
  }

  const style = {
    boxSizing: 'border-box',
    border: 'none',
    caretColor: 'transparent',
    color: 'black',
    outline: 'none',
    padding: '0.8rem 1rem',
    border: '1px solid lightgray',
    borderRadius: '2px',
    cursor: 'pointer',
    width: '100%',
  }

  return (
    <div style={{ width: '180px' }} className="d-flex flex-column gap-2 mt-3">
      <b><p className='m-0'>Date Range:</p></b>
      <InputDate placeholder={"Start Date"} value={new Date(startDate)} onChange={setStartDate} />
      <InputDate placeholder={"End Date"} value={new Date(endDate)} onChange={setEndDate} />
      <b><p className='m-0'>For year:</p></b>
      <Datetime
        isValidDate={valid}
        value={new Date(`${forYear}`)}
        closeOnSelect
        inputProps={{ className: '', style }}
        timeFormat={false}
        dateFormat="YYYY"
        onChange={setYear}
      />
    </div>
  )
}

export default DateRange;

import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsSavedFilterAppliedFilters, setQuoteFilterQuery } from '../../../store/quoteFilterSlice';
import Loader from '../../../components/Common/Loader';

const QuoteFilterContext = createContext();

export const useQuoteFilters = () => useContext(QuoteFilterContext);

export const QuoteFilterProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const [reloadFilter, setReloadFilter] = useState(true);
  const [savedFilterId, setSavedFilterId] = useState(null);
  const [tempFilters, setTempFilters] = useState({});
  const [filters, setFilters] = useState({});
  const init = async () => {
    const tempFilter = (await JSON.parse(localStorage.getItem('tempQuotesFilter'))) || {};
    if (tempFilter) {
      dispatch(setQuoteFilterQuery(tempFilter));
      dispatch(setIsSavedFilterAppliedFilters());
      setFilters(tempFilter);
      setIsInitialized(true);
    }
  };
  useEffect(() => {
    init();
    window.addEventListener('beforeunload', function () {
      localStorage.removeItem('qTPn');
    });
  }, []);

  const updateFilter = useCallback((key, value) => {
    setFilters((prev) => {
      if (key == 'page') localStorage.setItem('qTPn', value);
      if ('page' in prev && key != 'page') {
        if (key == 'searchTerm' && (!value || value?.length <= 0)) return { ...prev, [key]: [value], page: [localStorage.getItem('qTPn')] };
        localStorage.setItem('qTPn', prev?.page?.[0]);
        return { ...prev, [key]: [value], page: ['1'] };
      } else {
        return { ...prev, [key]: [value] };
      }
    });
  }, []);

  const updateTempFilter = useCallback((list) => {
    setTempFilters((prev) => ({ ...prev, ...list }));
  }, []);

  const applyButtonFilter = useCallback(
    (key, value) => {
      setFilters((prev) => {
        if ('page' in prev && (prev?.page?.[0] > 1 || prev?.page?.[0] != '1')) {
          return { ...prev, ...tempFilters, page: ['1'] };
        } else {
          return { ...prev, ...tempFilters };
        }
      });
    },
    [tempFilters, filters]
  );

  const handleSetSavedFilterId = (id) => {
    setSavedFilterId(id);
  };
  const updateFilterByList = useCallback((list) => {
    setFilters((prev) => ({ ...prev, ...list }));
  }, []);

  const addFilterList = useCallback((list, id = null) => {
    if (id) {
      setSavedFilterId(id);
    }
    setFilters((prev) => list);
  }, []);

  const updateFilterByListTags = useCallback((list) => {
    setFilters((prev) => {
      if (prev?.excludeTags?.length > 0 || prev?.includeTags?.length > 0) {
        return { ...prev, ...list };
      }
      return prev;
    });
  }, []);

  const addFilter = useCallback((key, value) => {
    setFilters((prev) => {
      if ('page' in prev && (prev?.page?.[0] > 1 || prev?.page?.[0] != '1')) {
        return {
          ...prev,
          page: ['1'],
          [key]: prev[key]?.includes(value) ? prev[key].filter((f) => f !== value) : [...(prev[key] || []), value]
        };
      } else {
        return { ...prev, [key]: prev[key]?.includes(value) ? prev[key].filter((f) => f !== value) : [...(prev[key] || []), value] };
      }
    });
  }, []);

  const buildQuery = useCallback(() => {
    return Object.entries(filters)
      ?.map(([key, values]) => {
        // Filter out undefined values before encoding
        const filteredValues = values.filter(value => value !== undefined && value !== null);
        return `${encodeURIComponent(key)}=${filteredValues.map(value => encodeURIComponent(value)).join(',')}`;
      })
      .join('&');
  }, [filters]);

  useEffect(() => {
    const temp = { ...filters };
    if (temp && typeof temp === 'object') {
      delete temp['searchTerm'];
      delete temp['limit'];
    }
    localStorage.setItem('tempQuotesFilter', JSON.stringify(temp));
  }, [filters]);

  if (!isInitialized) {
    return <Loader />;
  }

  return (
    <QuoteFilterContext.Provider
      value={{
        filters,
        savedFilterId,
        updateFilter,
        buildQuery,
        updateTempFilter,
        applyButtonFilter,
        handleSetSavedFilterId,
        addFilter,
        updateFilterByList,
        updateFilterByListTags,
        addFilterList,
        setReloadFilter,
        reloadFilter
      }}
    >
      {children}
    </QuoteFilterContext.Provider>
  );
};

export const useQuoteFilter = () => {
  const {
    updateFilter,
    buildQuery,
    handleSetSavedFilterId,
    savedFilterId,
    updateFilterByListTags,
    filters,
    updateTempFilter,
    applyButtonFilter,
    addFilterList,
    addFilter,
    updateFilterByList,
    setReloadFilter,
    reloadFilter
  } = useQuoteFilters();
  return {
    updateFilter,
    buildQuery,
    filters,
    savedFilterId,
    handleSetSavedFilterId,
    addFilter,
    addFilterList,
    updateFilterByListTags,
    updateFilterByList,
    updateTempFilter,
    applyButtonFilter,
    setReloadFilter,
    reloadFilter
  };
};

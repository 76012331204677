import React from 'react'
import Pagination from '../../../../components/Common/Pagination'
// import { FormField } from "@jobber/components/FormField";

export default function Footer({ currentPage, totalPages, handlePageChange, handleLimitChange }) {
  return (
    <div className='d-flex align-items-center justify-content-end gap-2'>
      <div style={{ width: '150px' }} className='mb-3'>
        {/* <FormField
          placeholder={"Choose limit"}
          type={"select"}
          name={"quotelimit"}
          size='small'
          defaultValue={500}
          onChange={handleLimitChange}
        >
          <option>500</option>
          <option>1000</option>
          <option>1500</option>
        </FormField> */}
      </div>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';
import Page from '../Pages/Page';
import { useDispatch, useSelector } from 'react-redux';
import { getFromServer } from '../../../components/Common/requests';
import { setTabs } from '../../../store/AdvanceQuotes/TabsSlice';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { RiEditCircleFill } from 'react-icons/ri';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useLocation } from 'react-router';

function TabEditButton({ show, item, editHandler }) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {item?.pageDescription}
    </Tooltip>
  );
  return (
    <>
      {item?.pageDescription ? (
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
          <div className="custom-tab-title">
            {item.pageName}
            {show && (
              <span className="edit-button" onClick={editHandler}>
                <RiEditCircleFill className="fs-4" />
              </span>
            )}
          </div>
        </OverlayTrigger>
      ) : (
        <div className="custom-tab-title">
          {item.pageName}
          {show && (
            <span className="edit-button" onClick={editHandler}>
              <RiEditCircleFill className="fs-4" />
            </span>
          )}
        </div>
      )}
    </>
  );
}

function TableTabs({ tabChangeHandler, setVisibleColumns, show = true }) {
  const location = useLocation();
  const [isAdvanceQuotes, setIsAdvanceQuotes] = useState(location?.pathname.includes('recycle') ? false : true);
  useEffect(() => {
    setIsAdvanceQuotes(location?.pathname.includes('recycle') ? false : true);
  }, [location]);
  const cPageId = localStorage.getItem(isAdvanceQuotes ? 'cTab' : 'rcTab');
  const [tab, setTab] = cPageId ? useState(cPageId) : useState();
  const tabs = useSelector((store) => store.tabs);
  const [showPage, setShowPage] = useState(false);
  const [item, setItem] = useState(null);
  const editHandler = (item) => {
    setShowPage(true);
    setItem(item);
  };

  useEffect(() => {
    setTab(cPageId);
  }, [cPageId])

  return (
    <>
      {showPage && (
        <Page
          setVisibleColumns={setVisibleColumns}
          showPage={showPage}
          setTab={setTab}
          setShowPage={setShowPage}
          tabChangeHandler={tabChangeHandler}
          setItem={setItem}
          item={item}
        />
      )}
      <div className="sticky tab__sticky z10 d-flex gap-2">
        {isAdvanceQuotes ? (
          tabs?.tabs?.length > 0 ? (
            <Tabs
              id="controlled-tab-example"
              activeKey={tab}
              onSelect={(k) => {
                setTab(k);
                tabChangeHandler(k);
              }}
              className={`mb-3 ${isAdvanceQuotes ? 'custom-tab' : 'recycle-custom-tab'}`}
            >
              {tabs.tabs.map((item, index) => {
                return <Tab eventKey={item._id} title={<TabEditButton show={show} item={item} editHandler={() => editHandler(item)} />}></Tab>;
              })}
            </Tabs>
          ) : (
            ''
          )
        ) : tabs?.recycleTabs?.length > 0 ? (
          <Tabs
            id="controlled-tab-example"
            activeKey={tab}
            onSelect={(k) => {
              setTab(k);
              tabChangeHandler(k);
            }}
            className={`mb-3 ${isAdvanceQuotes ? 'custom-tab' : 'recycle-custom-tab'}`}
          >
            {tabs.recycleTabs.map((item, index) => {
              return (
                <Tab eventKey={item._id} title={<TabEditButton show={show} item={item} editHandler={() => editHandler(item)} />}></Tab>
              );
            })}
          </Tabs>
        ) : (
          ''
        )}
        {isAdvanceQuotes && show ? (
          <FaCirclePlus
            className="fs-4 mt-2 text-green animate"
            onClick={() => {
              setShowPage(true);
              setItem(null);
            }}
          />
        ) : ""}
      </div>
    </>
  );
}

export default TableTabs;

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../../SocketProvider';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { ProgressBar } from 'react-bootstrap';
import { setStartSyncR, setStopSyncR } from '../../store/layoutSlice';

const dropdown = [
	{
		label: 'Sync Clients',
		link: '/clients'
	},
	{
		label: 'Sync Quotes',
		link: '/advance-quotes'
	},
	{
		label: 'Sync Visits',
		link: '/advance-visits'
	},
	{
		label: 'Sync Jobs',
		link: '/job-automation?tab=closed-jobs'
	},
	{
		label: 'Sync Notes',
		link: '/advance-notes'
	}
];

function Sync() {
	const [show, setShow] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const navigateHandler = (href) => {
		navigate(href, { state: { sync: true } });
		setShow(false);
	};

	const { socket, socketHandler } = useContext(SocketContext);
	const [loading, setLoading] = useState(false);
	const [comingMsg, setComingMsg] = useState(null);
	const [progressValue, setProgressValue] = useState(1);

	const userStore = useSelector((store) => store.user);
	const sync = useSelector((store) => store.layout);
	const user = userStore?.user?.data;

	useEffect(() => {
		if (sync.start && !loading) {
			setLoading(true);
			dispatch(setStartSyncR(false));
		}
	}, [sync.start]);

	useEffect(() => {
		if (comingMsg && !loading) {
			setLoading(true);
		}
		if (comingMsg) {
			setProgressValue(comingMsg?.progress);
		}
		if (comingMsg?.progress == '100' || comingMsg?.progress == 100) {
			setTimeout(() => {
				setProgressValue(1);
				setLoading(false);
			}, 1000);
		}
	}, [comingMsg]);

	useEffect(() => {
		if (!socket) {
			const newSocket = io(process.env.REACT_APP_API_URL);
			socketHandler(newSocket);
		}

		if (socket) {
			socket.on(`Genie_progress_${user?._id}`, (notify) => {
				setComingMsg(notify);
			});
			socket.on(`Genie_Quote_progress_${user?._id}`, (notify) => {
				setComingMsg(notify);
			});
			socket.on(`Genie_Visit_progress_${user?._id}`, (notify) => {
				setComingMsg(notify);
			});
			socket.on(`Genie_notes_progress_${user?._id}`, (notify) => {
				setComingMsg(notify);
			});
			socket.on(`Genie_Job_progress_${user?._id}`, (notify) => {
				console.log('socket called', notify);
				setComingMsg(notify);
			});

			socket.on(`Genie_Sync_End_${user?._id}`, (notify) => {
				setLoading(false);
				setProgressValue(1);
			});

			socket.on(`Genie_Quote_syncCompleted_${user?._id}`, (notify) => {
				setProgressValue(1);
				setLoading(false);
			});

			socket.on(`Genie_Quote_syncStarted_${user?._id}`, (notify) => {
				setLoading(true);
			});

			socket.on(`QuickBooksSyncStart_${user?._id}`, (msg) => {
				setLoading(true);
				setProgressValue(msg.progress);
			});

			socket.on(`QuickBooksSyncProgress_${user?._id}`, (msg) => {
				setLoading(true);
				setProgressValue(msg.progress);
			});

			socket.on(`QuickBooksSyncComplete_${user?._id}`, (msg) => {
				setLoading(false);
				setProgressValue(msg.progress);
			});

			socket.on(`QuickBooksSyncError_${user?._id}`, (msg) => {
				setLoading(false);
				setProgressValue(msg.progress);
			});
		}
	}, [socket]);

	const joinRoom = async () => {
		if (socket && user?._id) {
			await socket.emit('joinGenieRoom', { userId: user?._id });
		}
	};

	useEffect(() => {
		if (user?.isQuoteSyncing) {
			setLoading(true);
			setProgressValue(user?.progress);
		} else {
			setLoading(false);
			setProgressValue(1);
		}
	}, [user]);

	useEffect(() => {
		joinRoom();
	}, [user?._id, socket]);

	return (
		<>
			{loading ? (
				<div style={{ width: '30vw' }} className="mt-2">
					<ProgressBar animated variant="success" label={`${Number(progressValue).toFixed(2)}%`} now={progressValue} />
				</div>
			) : (
				<div className="position-relative">
					<button className="btn btn-sm btn-success" onClick={() => setShow(!show)}>
						Sync
					</button>
					{show && (
						<div className="position-absolute" style={{ left: '-1.4rem' }}>
							<div className="card">
								<div className="d-flex flex-column gap-1 py-2 sync__links">
									{dropdown?.map((d) => (
										<span className="ps-2 pe-2 text-nowrap" onClick={() => navigateHandler(d.link)}>
											{d.label}
										</span>
									))}
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
}

export default Sync;

import { Chip } from '@jobber/components/Chip';
import { Combobox } from '@jobber/components/Combobox';
import { Divider } from '@jobber/components/Divider';
import { Icon } from '@jobber/components/Icon';
import React, { useEffect, useRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Popover } from "@jobber/components/Popover";
import { Content } from '@jobber/components/Content';

function useDebounce(callback, delay) {
  const timeoutRef = useRef(null);

  function debouncedFunction(...args) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return debouncedFunction;
};

export default function Filters({ getTags, initialValue }) {
  const tagSlice = useSelector((store) => store.tag);

  const [includedSelected, setIncludedSelected] = useState(initialValue ? initialValue.include.map(tag => ({ id: tag, label: tag })) : []);
  const [excludedSelected, setExcludedSelected] = useState(initialValue ? initialValue.exclude.map(tag => ({ id: tag, label: tag })) : []);

  const updateTags = useDebounce((included, excluded) => {
    getTags({
      include: included.map(tag => tag.label),
      exclude: excluded.map(tag => tag.label),
    });
  }, 500);

  function getSelected(value, type) {
    if (type === 'include') {
      setIncludedSelected(value);
      updateTags(value, excludedSelected);
    }
    if (type === 'exclude') {
      setExcludedSelected(value);
      updateTags(includedSelected, value);
    }
  }

  function removeHandler(index, type) {
    if (type === 'include') {
      const updatedIncluded = includedSelected.filter((_, i) => index !== i);
      setIncludedSelected(updatedIncluded);
      updateTags(updatedIncluded, excludedSelected);
    }
    if (type === 'exclude') {
      const updatedExcluded = excludedSelected.filter((_, i) => index !== i);
      setExcludedSelected(updatedExcluded);
      updateTags(includedSelected, updatedExcluded);
    }
  }

  return (
    <div className='d-flex flex-column gap-2'>
      <Combo heading={'Include Tags'} type={'include'} selected={includedSelected} slice={tagSlice} getSelected={getSelected} removeHandler={removeHandler} />
      <Divider />
      <Combo heading={'Exclude Tags'} type={'exclude'} selected={excludedSelected} slice={tagSlice} getSelected={getSelected} removeHandler={removeHandler} />
    </div>
  )
}

function Combo({ heading, type, selected, slice, getSelected, removeHandler }) {
  const [visibleCount, setVisibleCount] = useState(0);
  const [showPopover, setShowPopover] = useState(undefined);
  const popoverRef = useRef(undefined);
  const containerRef = useRef();

  useEffect(() => {
    const calculateVisibleTags = () => {
      const container = containerRef.current;
      if (!container) return;

      const containerRect = container.getBoundingClientRect(); // Get container dimensions
      const tagElements = container.querySelectorAll('.tag');

      let count = 0;
      tagElements.forEach((tag) => {
        const tagRect = tag.getBoundingClientRect();
        const tagWidth = tagRect.right - tagRect.left;
        const tagHeight = tagRect.bottom - tagRect.top;

        const visibleWidth = Math.min(tagRect.right, containerRect.right) - Math.max(tagRect.left, containerRect.left);
        const visibleHeight = Math.min(tagRect.bottom, containerRect.bottom) - Math.max(tagRect.top, containerRect.top);

        const isFullyVisible =
          visibleWidth >= tagWidth / 2 &&
          visibleHeight >= tagHeight / 2;

        const isPartiallyVisible =
          visibleWidth > 0 &&
          visibleHeight > 0;

        if (isFullyVisible || isPartiallyVisible) {
          count++;
        }
      });

      setVisibleCount(count); // Update the visible tag count
    };

    calculateVisibleTags(); // Calculate on mount

    window.addEventListener('resize', calculateVisibleTags); // Recalculate on resize
    return () => window.removeEventListener('resize', calculateVisibleTags);
  }, [selected]);

  return (
    <div className='d-flex align-items-center gap-2'>
      <Combobox
        multiSelect
        onSelect={(selection) => {
          getSelected(selection, type);
        }}
        selected={selected}
      >
        <Combobox.Activator>
          <Chip heading={heading} variation={'subtle'}>
            <Chip.Suffix>
              <Icon name={'add'} size={'large'} />
            </Chip.Suffix>
          </Chip>
        </Combobox.Activator>
        {slice?.allTags ? slice.allTags.map((t) => <Combobox.Option key={t._id} id={t.label} label={t.label} />) : null}
      </Combobox>
      <div className='d-flex gap-2 align-items-center'>
        <div ref={containerRef} style={{ flexWrap: 'wrap', maxHeight: '60px', maxWidth: '300px', overflow: 'hidden' }} className={`${selected?.length == 1 ? '' : 'd-flex gap-2'}`}>
          {selected &&
            selected?.map((t, index) => (
              <div style={{ paddingLeft: '0.18rem' }} className="d-flex gap-1 align-items-center border rounded-2 pe-1 tag" key={t._id}>
                <IoClose onClick={() => removeHandler(index, type)} className="fs-6 border rounded-1 bg-light pointer" />
                <p className='m-0'>{t.label}</p>
              </div>
            ))}
        </div>
        {selected?.length > visibleCount && (
          <>
            <div style={{ aspectRatio: 1 }} className='d-flex align-items-center border rounded-circle p-1 pointer'>
              <p ref={popoverRef} onClick={() => setShowPopover(!showPopover)} className='m-0 fs-6'>{`+${selected.length - visibleCount}`}</p>
            </div>
            <Popover
              attachTo={popoverRef}
              open={showPopover}
              onRequestClose={() => setShowPopover(false)}
              preferredPlacement='bottom'
            >
              <Content>
                <div style={{ maxHeight: '400px', overflowY: 'auto' }} className='d-flex flex-column gap-1'>
                  {selected &&
                    selected?.map((t, index) => (
                      <div style={{ paddingLeft: '0.18rem' }} className="d-flex gap-1 align-items-center border rounded-2 p-1 tag" key={t._id}>
                        <IoClose onClick={() => removeHandler(index, type)} className="fs-6 border rounded-1 bg-light pointer" />
                        <p className='m-0'>{t.label}</p>
                      </div>
                    ))}
                </div>
              </Content>
            </Popover>
          </>
        )}
      </div>
    </div>
  )
}

const TagRow = ({

})

import React, { useState } from 'react'
import useGetFromServer from '../../utils/useGetFromServer';
import MainSection from './_components/MainSection/main';
import ResetSidebar from '../../components/Common/ResetSidebar';
import Header from './_components/Header/Header';
import { addUniqueIds } from '../../components/Common/Validations';
import { useDispatch, useSelector } from 'react-redux';
import { setAllTags } from '../../store/tagsSlice';
import PrepProgress from './_components/Progress/progress';
import { Button } from '@jobber/components/Button';
import { Divider } from '@jobber/components/Divider';

export function QuotesPreparation() {
  const dispatch = useDispatch()
  const savedData = JSON.parse(localStorage.getItem('quotesPreparationData')) ?? {}
  const [selectedYear, setSelectedYear] = useState(savedData.selectedYear ?? new Date().getFullYear())
  const [tags, setTags] = useState(savedData.tags ?? { include: [], exclude: [] })
  const [titles, setTitles] = useState(savedData.titles ?? [])
  const [loading, setLoading] = useState(false)
  const [progressloading, setProgressLoading] = useState(false)

  const userStore = useSelector((store) => store.user);
  const user = userStore?.user?.data;

  const { data } = useGetFromServer('Client-Tags')
  if (data && Array.isArray(data) && data.length > 0) {
    const addUniqueId = addUniqueIds(data);
    dispatch(setAllTags(addUniqueId));
  }

  function getYear(year) {
    setSelectedYear(year)
    saveToLocalStorage({ selectedYear: year });
  }

  function getTags(tags) {
    setTags(tags)
    saveToLocalStorage({ tags });
  }

  function getTitles(gtitles) {
    const values = []
    gtitles.forEach((gtitles) => {
      values[gtitles.id] = gtitles.label
    })
    setTitles(values)
    saveToLocalStorage({ titles: values });
  }

  const saveToLocalStorage = (updatedData) => {
    const dataToSave = {
      selectedYear: updatedData?.selectedYear ?? selectedYear,
      tags: updatedData?.tags ?? tags,
      titles: updatedData?.titles ?? titles,
    };
    localStorage.setItem('quotesPreparationData', JSON.stringify(dataToSave));
  };

  const [processedPage, setProcessedPage] = useState(false)

  return (
    <>
      <ResetSidebar />
      <div className={`${(loading || progressloading) ? 'h-100 w-100 overflow-hidden' : 'position-relative'} d-flex flex-column gap-2`} style={{ width: '100%', height: '100%' }}>
        <div style={{
          position: 'absolute',
          inset: 0,
          placeItems: 'center',
          backgroundColor: 'white',
          zIndex: 4,
          display: `${(loading || progressloading || user?.isQuoteSyncing) ? 'grid' : 'none'}`
        }}>
          {loading && (<p>Loading</p>)}
          <div style={{ display: `${(user?.isQuoteSyncing || progressloading) ? 'block' : 'none'}` }}>
            <PrepProgress setLoading={setProgressLoading} />
          </div>
        </div>
        <Header getYear={getYear} getTags={getTags} getTitles={getTitles} />
        <Divider direction={"horizontal"} />
        <div className='d-flex gap-2'>
          <Button onClick={() => setProcessedPage(false)} variation={`${!processedPage ? 'work' : 'subtle'}`} label='Default' />
          <Button onClick={() => setProcessedPage(true)} variation={`${processedPage ? 'work' : 'subtle'}`} label='Processed' />
        </div>
        <Divider direction={"horizontal"} />
        <MainSection
          titles={titles}
          selectedYear={selectedYear}
          tags={tags}
          setLoading={setLoading}
          setProgressLoading={setProgressLoading}
          processedPage={processedPage}
        />
      </div>
    </>
  )
}
